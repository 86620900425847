import React from "react";
import { Nav, Container, Accordion, Card, Button, Col, Row, Tab, Carousel, Modal } from 'react-bootstrap'; 
import { Helmet } from "react-helmet";
import { withRouter, Route, Switch, Link, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbars from "./navbar";
import Footer from "./footer"; 
import "./App.css";
import Home from "./homepage";
import Thankyou from "./thankyou";
import Services from "./services";
import Freetools from "./free-tools";
import Freetutorials from "./free-tutorial";
import Html_Introduction from "./html/introduction";
import Html_Heading from "./html/heading";
import Html_Elements from "./html/elements"; 
import Html_Editors from "./html/editors";
import Html_Layout from "./html/layout";
import Html_Paragraph from "./html/paragraph";
import Html_Inlinestyle from "./html/inlinestyle";
import Html_formatting from "./html/formatting-tags";
import Html_comments from "./html/comments";
import Html_colors from "./html/colors";
const App = (props) => { 
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" /> 
      </Helmet>
      <Navbars/>
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
        <Route exact path={`${process.env.PUBLIC_URL}/thank-you.html`} component={Thankyou} />
        <Route exact path={`${process.env.PUBLIC_URL}/services.html`} component={Services} />
        <Route exact path={`${process.env.PUBLIC_URL}/free-ai-tools.html`} component={Freetools} />
        <Route exact path={`${process.env.PUBLIC_URL}/free-tutorials.html`} component={Freetutorials} />
        {/* html */}
        <Route exact path={`${process.env.PUBLIC_URL}/html/introduction.html`} component={Html_Introduction} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/heading.html`} component={Html_Heading} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/editors.html`} component={Html_Editors} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/elements.html`} component={Html_Elements} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/layouts.html`} component={Html_Layout} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/paragraph.html`} component={Html_Paragraph} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/inline-style.html`} component={Html_Inlinestyle} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/formatting-tags.html`} component={Html_formatting} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/comments.html`} component={Html_comments} />
        <Route exact path={`${process.env.PUBLIC_URL}/html/colors.html`} component={Html_colors} />
        </Switch>
      <Footer/> 
    </div>
  );
}

export default App;
