import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { HashLink } from "react-router-hash-link";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export default class Thankyou extends Component{
    render() {
        return (
            <div className="text-center">
                <br/>
               <h4 className="tph">Thankyou for<br/> contacting us</h4> 
            </div>
        )
    }
}