import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_Elements() {
  return (
    <div>
      <Helmet>
        <title>HTML Elements | Sahad Sarang</title>
        <meta name="description" content="Html Elements are the blocks of HTML documents and consist
              of a start tag, content, and an end tag." /> 
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Elements</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/layouts.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
            <h2 className="pt-5">What is Html Elements?</h2>
            <p>
              The Elements are the building blocks of HTML documents and consist
              of a start tag, content, and an end tag.
            </p>
            <p> Here's a basic example:</p>
            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {` <p> is the start tag.
                       This is a paragraph is the content.
                       </p> is the end tag.                                             
                    `}
                </code>
              </pre>
            </Container> 
            <br/>
            <h2 className="">Nested HTML Elements:</h2>
            <p>
              This example creates a simple webpage with a header, navigation
              menu, two sections, and a footer. The content inside each section
              is just placeholder text and can be replaced with actual content.
            </p>
            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {` <div>
  <h1>This is a heading</h1>
  <p>This is a paragraph inside a div.</p>
</div>
                                         
                    `}
                </code>
              </pre>
            </Container>
            <p>
              In this example, the &lt;h1&gt; (heading) and &lt;p&gt;
              (paragraph) elements are nested within the &lt;div&gt; (division)
              element.
            </p>
            <br/>
            <h2>Never Skip the End Tag:</h2>
            <p>
              It's important to always include the end tag for HTML elements,
              except for certain elements that are intentionally designed to be
              self-closing (discussed later). Skipping the end tag can lead to
              unexpected behavior and errors.  
            </p>
            <p>For example:</p>
            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {` <p>This is a paragraph without an end tag 

              This is incorrect and may cause issues. Always close tags properly: 
              
              <p>This is a paragraph with the correct end tag</p>
                `}
                </code>
              </pre>
            </Container>
            <br/>
            <h2>Empty HTML Elements:</h2>
            <p>
              Some HTML elements are empty, meaning they don't have any content
              and don't require an end tag. These are usually self-closing tags.
              For example, the line break &lt;br&gt; and image &lt;img&gt; tags:
            </p>
            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {` <p>This is a paragraph.<br>This is a new line.</p>
<img src="image.jpg" alt="An example image" > 
                `}
                </code>
              </pre>
            </Container>
            <br/>
            <h2>HTML is Not Case Sensitive:</h2>
            <p>HTML is not case-sensitive, meaning you can use uppercase or lowercase letters for tags and attributes. However, it's a widely adopted convention to use lowercase for HTML tags and attributes.</p>
           <p>For Example:</p>
            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {`<P>This is a paragraph using uppercase tags</P>

This is valid but not commonly practiced. It's recommended to stick to lowercase for consistency and better readability:
 
<p>This is a paragraph using lowercase tags</p>

                `}
                </code>
              </pre>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_Elements;
