import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_Inlinestyle() {
  return (
    <div>
      <Helmet>
        <title>HTML Inline Style | Sahad Sarang</title>
        <meta
          name="description"
          content="Learn how to use inline styles effectively in HTML to apply CSS styling directly within your HTML tags."
        />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Inline Style</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/formatting-tags.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
            <h2 className="pt-5">What is Inline Style in HTML?</h2>
            <p>
              Inline style in HTML refers to the practice of applying styling
              directly to a specific HTML element, rather than using an external
              stylesheet or internal styles within a &lt;style&gt; tag. This
              approach allows developers to apply unique styles to individual
              elements, providing a fine-grained level of control over the
              appearance of their web pages.
            </p>

            <h2 className="pt-3">How to Define Inline Style in HTML:</h2>
            <p>
              To define an inline style in HTML, you can use the style attribute
              within the opening tag of the element you want to style. The style
              attribute contains CSS properties and values that determine the
              appearance of the element.
            </p>
            <p>Check below example:</p>
            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {` <p style="color: blue; font-size: 16px;">This is a styled paragraph.</p> `}
                </code>
              </pre>
            </Container>
            <p className="pt-3">
            In this example, the style attribute is applied to the &lt;p&gt; (paragraph) element, setting the text color to blue and the font size to 16 pixels.
            </p>
             
            <h3>How to Add Inline Style in HTML:</h3>
           <p>
           Adding inline styles to your HTML elements is a straightforward process. <br/>
Follow these steps: <br/>
Identify the HTML element you want to style. <br/>
Add the style attribute within the opening tag of the element. <br/>
Inside the style attribute, specify the desired CSS properties and values separated by semicolons. <br/>
Here's an example of adding an inline style to an &lt;h1&gt; (heading) element: 
           </p>
           <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {` <h1 style="text-align: center; color: #e74c3c;">Centered and red heading</h1> `}
                </code>
              </pre>
            </Container>
            <p>In this example, the text alignment is set to center, and the text color is defined as a shade of red.
</p>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_Inlinestyle;
