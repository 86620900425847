import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LatestPosts from "./latest-post";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Freetutorials() {
  return (
    <div>
      <Helmet>
        <title>Free Tutorials: Learn and Grow at sahadsarang.com</title>
        <meta
          name="title"
          content="Free Tutorials: Learn and Grow at sahadsarang.com"
        />
        <meta
          name="description"
          content="Explore a wealth of knowledge with Sahad Sarang's free tutorials. Whether you're a beginner or an enthusiast, our SEO-friendly lessons are designed for easy understanding and practical learning. Start your educational journey now, completely free, at sahadsarang.com!"
        />
      </Helmet>
     {/* Free Tutorials */}
     <Container id="freetutorials" fluid className="padtb15 bgfreetutorials pt-5 ">
        <h2 className="text-left pt-8">FREE TUTORIALS</h2>
        <Row className="">
        
          <Col lg="4" md="4" className="tutorialbox mbbpadtb3">
          <Link to="html/introduction.html">
            <img src="images/courses-orange-blue.png" width="100%" />
            <div className="tutorial-left">
              <h3 className="tcwhite">
                HTML5 Full
                <br /> Course
              </h3>
              <a href="" className="btn btn-white">
                Learn Now
              </a>
            </div>
            <div className="tutorial-right">
              <img src="images/html.png" width="50%" />
            </div>
            
          </Link>
          </Col>
          <Col lg="4" md="4" className="tutorialbox mbbpadtb3">
            <img src="images/courses-blue.png" width="100%" />
            <div className="tutorial-left">
              <h3>
                CSS3 Full
                <br /> Course
              </h3>
              <a href="" className="btn btn-white">
                Learn Now
              </a>
            </div>
            <div className="tutorial-right">
              <img src="images/css-3.png" width="50%" />
            </div>
          </Col>
          <Col lg="4" md="4" className="tutorialbox mbbpadtb3">
            <img src="images/courses-yellow-blue.png" width="100%" />
            <div className="tutorial-left">
              <h3>
                Javascript Full
                <br /> Course
              </h3>
              <a href="" className="btn btn-white">
                Learn Now
              </a>
            </div>
            <div className="tutorial-right">
              <img src="images/java-script-logo.png" width="50%" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Freetutorials;
