import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_Paragraph() {
  return (
    <div>
      <Helmet>
        <title>HTML Paragraph | Sahad Sarang</title>
        <meta
          name="description"
          content="Discover the fundamentals of HTML paragraphs, including how to structure and style them effectively for your web content. Learn best practices and tips for creating compelling paragraphs on your website."
        />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Paragraph</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/inline-style.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
             
            <p className="pt-5">
              One of the fundamental elements in HTML is the paragraph, used to
              structure and organize content on a webpage. In this tutorial, we
              will explore how to add paragraphs in HTML and customize them
              using various attributes.
            </p>

            <h2 className="pt-3">How to Add a Paragraph in HTML</h2>
            <p>
              To create a paragraph in HTML, you use the `&lt;p&gt;` tag. Here's
              a basic example:
            </p>

            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {`  <p>This is a simple paragraph.</p> `}
                </code>
              </pre>
            </Container>
            <p className="pt-3">
              The `&lt;p&gt;` tag is the opening tag, and `&lt;/p&gt;` is the
              closing tag. Any text placed between these tags will be treated as
              a paragraph.
            </p>
            <p>
              Now, let's delve into some commonly used attributes and styles for
              HTML paragraphs.
            </p>
            <h3>HTML Paragraph Spacing, Line Spacing, Color and Alignment </h3>
            <ul>
              <li>
                <h4>Paragraph Spacing</h4>
                <p>
                  If you want to add space above and below a paragraph, you can
                  use the CSS 'margin' property. For example:
                </p>
                <Container className="code-container">
                  <pre class="language-markup snipcss-CiZSg" tabindex="0">
                    <code class="language-markup">
                      {`<style>
                        p {
                            margin: 10px 0;
                        }
                        </style>

                        <p>This paragraph has spacing above and below.</p>`}
                    </code>
                  </pre>
                </Container>
                <p>
                  In this example, `10px` represents the top and bottom margins.
                  You can adjust this value to achieve the desired spacing.
                </p>
              </li>
              <li>
                <h4>Line Spacing</h4>
                <p>
                Line spacing within a paragraph is controlled by the CSS 'line-height' property. For instance:
                </p>
                <Container className="code-container">
                  <pre class="language-markup snipcss-CiZSg" tabindex="0">
                    <code class="language-markup">
                      {`<style>
                        p {
                            line-height: 1.5;
                        }
                        </style>

                        <p>This paragraph has increased line spacing.</p>
                        `}
                    </code>
                  </pre>
                </Container>
                <p>
                The `1.5` value is a multiplier of the font size, adjusting the space between lines.
                </p>
              </li>
              <li>
                <h4>Paragraph Indent</h4>
                <p>
                To add an indent to the first line of a paragraph, you can use the CSS 'text-indent' property:
                </p>
                <Container className="code-container">
                  <pre class="language-markup snipcss-CiZSg" tabindex="0">
                    <code class="language-markup">
                      {`<style>
                        p {
                            text-indent: 20px;
                        }
                        </style>

                        <p>This paragraph has an indent on the first line.</p>
                        `}
                    </code>
                  </pre>
                </Container>
                <p>The `20px` value represents the size of the indent.</p>
              </li>
              <li>
                <h4>Paragraph Color</h4>
                <p>
                Changing the color of text in a paragraph involves using the CSS 'color' property:
                </p>
                <Container className="code-container">
                  <pre class="language-markup snipcss-CiZSg" tabindex="0">
                    <code class="language-markup">
                      {`<style>
                        p {
                            color: blue;
                        }
                        </style>

                        <p>This paragraph has blue text.</p>
                        `}
                    </code>
                  </pre>
                </Container>
                <p>
                You can substitute "blue" with any valid color name or use hexadecimal or RGB values.
                </p>
              </li>
              <li>
                <h4>Paragraph Alignment</h4>
                <p>
                Aligning paragraphs can be done using the CSS 'text-align' property:
                </p>
                <Container className="code-container">
                  <pre class="language-markup snipcss-CiZSg" tabindex="0">
                    <code class="language-markup">
                       {`<style>
                        p {
                            text-align: center;
                        }
                        </style>

                        <p>This paragraph is centered.</p>
                        `}
                    </code>
                  </pre>
                </Container>
                <p>You can choose from various values like "left," "right," "center," or "justify" to align text accordingly.</p>
              </li>
              
            </ul>
             
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_Paragraph;
