import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LatestPosts from "./latest-post";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Freetools() {
  return (
    <div>
      <Helmet>
        <title>Get Free and Easy AI Tools Here!</title>
        <meta
          name="title"
          content="Get Free and Easy AI Tools Here!"
        />
        <meta
          name="description"
          content="Discover the magic of AI with Sahad Sarang's free tools! Make things simple, have fun, and explore the world of AI without any cost. Dive into creativity and innovation – it's easy, it's free, and it's all yours at sahadsarang.com!"
        />
      </Helmet>
      <Container fluid className="text-center navbelowmg">
        <h4 className="pt-5">Free AI tools Coming Soon</h4>
      </Container>
    </div>
  );
}
export default Freetools;
