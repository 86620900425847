import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_Layout() {
  return (
    <div>
      <Helmet>
        <title>HTML Layout | Sahad Sarang</title>
        <meta
          name="description"
          content="HTML layout is a fundamental aspect of web development, playing a
          crucial role in structuring and organizing content on a webpage."
        />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Layouts</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/heading.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
            <h2 className="pt-5">What is Html Layout?</h2>
            <p>
              HTML layout is a fundamental aspect of web development, playing a
              crucial role in structuring and organizing content on a webpage.
              In this tutorial, we will explore HTML layout concepts and provide
              practical examples to help you create well-structured and visually
              appealing web pages.
            </p>
           
            <h2 className="pt-3">Understanding HTML Layout:</h2>
            <h4>HTML5 Structural Elements</h4>
            <div className="text-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/html/Html-Website-Basic-Layout.png`}
              width="80%"
              className="text-center"
            /></div>

            <p className="pt-3">
              HTML5 introduces semantic structural elements that make it easier
              to create meaningful and well-organized layouts. Learn about
              elements such as `&lt;header&gt;`, `&lt;nav&gt;`, `&lt;main&gt;`,
              `&lt;article&gt;`, `&lt;section&gt;`, and `&lt;footer&gt;`.
            </p>
            <h4>Box Model</h4>
            <p>
              Understand the box model, which consists of content, padding,
              border, and margin. Explore how these components interact to
              define the layout of an HTML element.
            </p>

            <h2 className="pt-3">Building Blocks of HTML Layout:</h2>
            <ul>
              <li>
                <h4>Div Element</h4>

                <p>
                  Explore the `&lt;div&gt;` element and how it is commonly used
                  as a container to group and structure content. Learn how to
                  style and position `&lt;div&gt;` elements to create flexible
                  layouts.
                </p>
              </li>
              <li>
                <h4>Span Element:</h4>
                <p>
                  Understand the purpose of the `&lt;span&gt;` element and its
                  role in styling inline content. Discover how it differs from
                  the `&lt;div&gt;` element.
                </p>
              </li>
            </ul>
            <h4>Basic Webpage Layout</h4>
            <p>
              A basic webpage layout using HTML5 semantic elements and CSS
              styling.
            </p>
            <iframe
              height="500px"
              width="100%"
              scrolling="no"
              title="Html Simple Page Layout Example"
              src="https://codepen.io/developersahad/embed/xxBzrvG?default-tab=result&theme-id=dark"
              frameborder="no"
              loading="lazy"
              allowtransparency="true"
              allowfullscreen="true"
            >
              See the Pen{" "}
              <a href="https://codepen.io/developersahad/pen/xxBzrvG">
                Html Simple Page Layout Example
              </a>{" "}
              by Developer Sahad Sarang (
              <a href="https://codepen.io/developersahad">@developersahad</a>)
              on <a href="https://codepen.io">CodePen</a>.
            </iframe>
            <h2 className="pt-5">Css for Layout:</h2>
            <ul>
              <li>
                <h4>Introduction to CSS</h4>
                <p>
                  Learn the basics of Cascading Style Sheets (CSS) and how it is
                  used to style HTML elements. Understand the different ways to
                  apply styles: inline, internal, and external.
                </p>
              </li>
              <li>
                <h4>Flexbox</h4>
                <p>
                  Dive into the flexbox layout model, a powerful and flexible
                  CSS feature for designing complex layouts with ease.
                  Understand the properties like `display`, `flex-direction`,
                  and `justify-content`.
                </p>
              </li>
              <li>
                <h4>Grid Layout</h4>
                <p>
                  Discover the CSS Grid Layout, a two-dimensional layout system
                  that allows you to create complex layouts with rows and
                  columns. Learn about properties like `grid-template-columns`
                  and `grid-template-rows`.
                </p>
              </li>
            </ul>
            <h4 className="pt-3">Grid-Based Portfolio</h4>
            <p>
              Design a portfolio page using CSS Grid Layout to organize content
              in a grid structure.
            </p>
            <iframe
              height="550px"
              width="100%"
              scrolling="no"
              title="Portfolio Sample"
              src="https://codepen.io/developersahad/embed/dyrKBoE?default-tab=result&theme-id=dark"
              frameborder="no"
              loading="lazy"
              allowtransparency="true"
              allowfullscreen="true"
            >
              See the Pen{" "}
              <a href="https://codepen.io/developersahad/pen/dyrKBoE">
                Portfolio Sample
              </a>{" "}
              by Developer Sahad Sarang (
              <a href="https://codepen.io/developersahad">@developersahad</a>)
              on <a href="https://codepen.io">CodePen</a>.
            </iframe>
            <h2 className="pt-5">Responsive Design:</h2>
            <ul>
              <li>
                <h4>Media Queries</h4>
                <p>
                  Explore media queries to make your web pages responsive. Learn
                  how to apply different styles based on the device's
                  characteristics, such as screen size.
                </p>
              </li>
              <li>
                <h4>Mobile-First Design</h4>
                <p>
                  Understand the concept of mobile-first design and how it can
                  improve the user experience on various devices.
                </p>
              </li>
            </ul>
             
            <h4>Flexbox Gallery</h4>
            <p>
              A responsive image gallery with media query using Flexbox example to showcase the
              flexibility of this layout model.
            </p>
            <iframe
              height="500px"
              width="100%" 
              title="Flexbox Gallery"
              src="https://codepen.io/developersahad/embed/jOJKoYq?default-tab=result&theme-id=dark"
              frameborder="no"
              loading="lazy"
              allowtransparency="true"
              allowfullscreen="true"
            >
              See the Pen{" "}
              <a href="https://codepen.io/developersahad/pen/jOJKoYq">
                Flexbox Gallery
              </a>{" "}
              by Developer Sahad Sarang (
              <a href="https://codepen.io/developersahad">@developersahad</a>)
              on <a href="https://codepen.io">CodePen</a>.
            </iframe>

            
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_Layout;
