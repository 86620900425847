import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";

import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_Introduction() {
  return (
    <div>
      <Helmet>
        <title>Introduction to HTML: Learn the Basics of Web Development</title>
        <meta name="description" content="Dive into the world of web development with our Introduction to HTML. Understand the basics of HTML and start your journey in web development. No prior coding experience required." />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Introduction</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/elements.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/editors.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
            <h2 className="pt-5"> What is HTML?</h2>
            <p>
              HTML, which stands for HyperText Markup Language, is the standard
              markup language for creating web pages. It is used to structure
              content on the web, providing a set of elements or tags that
              define the different parts of a webpage such as headings,
              paragraphs, links, images, forms, and more.
            </p>
            <p>Here's a simple HTML code example:</p>
            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {`
                       <!DOCTYPE html>
                       <html lang="en">
                       <head>
                           <meta charset="UTF-8">
                           <meta name="viewport" content="width=device-width, initial-scale=1.0">
                           <title>My First Webpage</title>
                       </head>
                       <body>
                           <header>
                               <h1>Welcome to My Webpage</h1>
                           </header> 
                       
                           <section>
                               <h2>About Me</h2>
                               <p>This is a brief introduction about me and my interests.</p>
                           </section>
                       
                           <section>
                               <h2>Contact Information</h2>
                               <p>Email: <a href="mailto:info@example.com">info@example.com</a></p>
                           </section>
                       
                           <footer>
                               <p>&copy; 2024 My Webpage. All rights reserved.</p>
                           </footer>
                       </body>
                       </html>                       
                    `}
                </code>
              </pre>
              <br />
              <p>
                Let me briefly explain the structure of this HTML example:
                <br />
                - &lt;!DOCTYPE html&gt; : Declares the document type and version
                of HTML.
                <br />
                - &lt;html lang="en"&gt; : The root element of the HTML
                document, indicating the document is in English.
                <br />
                - &lt;head&gt;: Contains meta-information about the HTML
                document, such as the character set, viewport settings, and the
                title of the page.
                <br />
                - &lt;body&gt;: Contains the content of the HTML document,
                including text, images, links, etc.
                <br />
                - &lt;header&gt;, &lt;nav&gt;, &lt;section&gt;, and
                &lt;footer&gt;: Semantic HTML5 elements that structure different
                parts of the webpage.
                <br />- &lt;h1&gt;, &lt;h2&gt;, &lt;p&gt;, &lt;ul&gt;,
                &lt;li&gt;, &lt;a&gt;: Various HTML tags used for headings,
                paragraphs, lists, and links.
              </p>
              <p>
                This example creates a simple webpage with a header, navigation
                menu, two sections, and a footer. The content inside each
                section is just placeholder text and can be replaced with actual
                content.
              </p>
              <h2>History of HTML?</h2>
              {/* table start*/}
              <table>
                <tr>
                  <th>Year</th>
                  <th>HTML Version</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>1991</td>
                  <td>HTML 1.0</td>
                  <td>Tim Berners-Lee creates the first version of HTML.</td>
                </tr>
                <tr>
                  <td>1995</td>
                  <td>HTML 2.0</td>
                  <td>
                    Introduced features like forms, text alignment, and inline
                    images.
                  </td>
                </tr>
                <tr>
                  <td>1997</td>
                  <td>HTML 3.2</td>
                  <td>
                    Brought support for tables, applets, text-flow around
                    images, and text colors.
                  </td>
                </tr>
                <tr>
                  <td>1999</td>
                  <td>HTML 4.01</td>
                  <td>
                    Included features like scripting (JavaScript), stylesheets
                    (CSS), and improved support for forms.
                  </td>
                </tr>
                <tr>
                  <td>2008</td>
                  <td>HTML 5.0</td>
                  <td>
                    Introduced a new parsing algorithm, new attributes, and a
                    range of new elements.
                  </td>
                </tr>
                <tr>
                  <td>2014</td>
                  <td>HTML 5.1</td>
                  <td>Minor updates and clarifications to HTML 5.0.</td>
                </tr>
                <tr>
                  <td>2016</td>
                  <td>HTML 5.2</td>
                  <td>Added new features and improvements to HTML 5.1.</td>
                </tr>
                <tr>
                  <td>2017</td>
                  <td>HTML 5.2</td>
                  <td>Continued updates and improvements.</td>
                </tr>
                <tr>
                  <td>2019</td>
                  <td>HTML 5.3</td>
                  <td>Draft proposals for potential future features.</td>
                </tr>
                <tr>
                  <td>2020+</td>
                  <td>Living Standard</td>
                  <td>
                    Ongoing updates and improvements; no fixed version number.
                  </td>
                </tr>
              </table>

              {/* table end */}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_Introduction;
