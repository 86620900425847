import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_comments() {
  return (
    <div>
      <Helmet>
        <title>HTML Comments | Sahad Sarang</title>
        <meta
          name="description"
          content="HTML comments are annotations added to your code that do not affect the webpage's appearance."
        />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Comments</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/colors.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
            <h2 className="pt-5">What are HTML Comments?</h2>
            <p>
              HTML comments are annotations added to your code that do not
              affect the webpage's appearance. Instead, they provide helpful
              information for developers, making the code more understandable
              and maintainable.
            </p>

            <h2>How to Make Comments in HTML</h2>
            <p>
              In HTML, comments are created using the{" "}
              <code>&lt;!-- and --&gt;</code> tags. Anything placed between
              these tags is treated as a comment and is not rendered on the
              webpage.
            </p>
            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {`<!-- This is an HTML comment -->`}
                </code>
              </pre>
            </Container>
            <h2>Single-line Comments</h2>
            <p>
              For single-line comments, you can simply place the comment text
              between the opening and closing comment tags.
            </p>

            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {`<!-- This is a single-line comment --!>`}
                </code>
              </pre>
            </Container>

            <h2>Multi-line Comments</h2>
            <p>
              If your comment spans multiple lines, you can use the same syntax,
              maintaining the <code>&lt;!-- and --&gt;</code> tags.
            </p>

            <Container className="code-container">
              <pre class="language-markup snipcss-CiZSg" tabindex="0">
                <code class="language-markup">
                  {`<!--
                  This is a multi-line comment.
                  It spans across several lines for clarity.
                --!>`}
                </code>
              </pre>
            </Container>
            <h2>Why Use HTML Comments?</h2>
            <ul>
              <li>
                <p>
                  <strong>Code Explanation</strong>: Comments serve as
                  documentation for your code, explaining its purpose or
                  functionality. This is especially helpful when revisiting code
                  after some time.
                </p>
              </li>

              <li>
                <p>
                  <strong>Collaboration</strong>: When working in a team,
                  comments facilitate collaboration by providing insights into
                  the code for other developers.
                </p>
              </li>
              <li>
                <p>
                  <strong>Debugging</strong>: Comments can be used to
                  temporarily disable sections of code during debugging without
                  deleting them.
                </p>
              </li>
              <li>
                <p>
                  <strong>Code Readability</strong>: Well-commented code is more
                  readable, making it easier for you and others to understand,
                  maintain, and modify.
                </p>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_comments;
