import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { HashLink } from "react-router-hash-link";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export default class Footer extends Component{
    render() {
        return (
            <div>
             <div className="footpara ">
      <p>© Copyright Sahad Sarang 2024</p>
    </div>    
            </div>
        )
    }
}