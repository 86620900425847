import React, { useEffect, useState } from 'react';
import {
    Nav,
    Container,
    Accordion,
    Card,
    Button,
    Col,
    Row,
    Tab,
    Carousel,
    Modal,
  } from "react-bootstrap";

const LatestPosts = () => {
  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    const fetchLatestPosts = async () => {
      try {
        const response = await fetch('https://sahadsarang.com/blog/wp-json/wp/v2/posts?_embed&per_page=8&order=desc');
        const data = await response.json();
        setLatestPosts(data);
      } catch (error) {
        console.error('Error fetching latest posts:', error);
      }
    };

    fetchLatestPosts();
  }, []);


  return (
    <div className='latestpost'>
    <h2>BLOG</h2>
    
    <ul className='col-lg-12'>
    <Row>
      {latestPosts.map(post => (
        <li key={post.id} className='col-lg-3 latestbox'>
               <a href={post.link} target="_blank" rel="noopener noreferrer">

          {post._embedded && post._embedded['wp:featuredmedia'] && (
            <img
              src={post._embedded['wp:featuredmedia'][0].media_details.sizes.large.source_url}
              alt={post.title.rendered} width="100%"
            />
          )}
          <div className='boxparalatest'>
             <h3>{post.title.rendered}</h3>
          <p className='pt-3' dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
          <a className='btn btn-default' href={post.link} target="_blank" rel="noopener noreferrer"> Read More </a>
          </div>
          </a>
          
        </li>
        
      ))}
        </Row>
    </ul>
  
  </div>
  );
};

export default LatestPosts;
