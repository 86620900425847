import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_formatting() {
  return (
    <div>
      <Helmet>
        <title>HTML Formatting | Sahad Sarang</title>
        <meta
          name="description"
          content="Learn how to apply bold, italic, underline, and other formatting styles to enhance the presentation of your content effectively."
        />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Formatting Tags</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/comments.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
            <h2 className="pt-5">What are HTML Formatting Tags?</h2>
            <p>
              HTML formatting tags are special codes that you include in your
              HTML document to define the structure and appearance of your text.
              These tags help you make your content visually appealing and easy
              to read.
            </p>

            <h3 className="pt-3">Commonly Used Formatting Tags:</h3>
            <p>
              <ol>
                <li>
                  {" "}
                  <h4>Bold Text:</h4>
                  <p>Use: To make text bold.</p>
                  <p>
                    Example:{" "}
                    <code>&lt;strong&gt;This text is bold&lt;/strong&gt;</code>{" "}
                    or <code>&lt;b&gt;This is also bold&lt;/b&gt;</code>.
                  </p>
                </li>
                <li>
                  <h4>Italic Text:</h4>
                  <p>Use: To make text italicized.</p>
                  <p>
                    Example:{" "}
                    <code>&lt;em&gt;This text is italicized&lt;/em&gt;</code> or{" "}
                    <code>&lt;i&gt;This is also italicized&lt;/i&gt;</code>.
                  </p>
                </li>
                <li>
                  <h4>Underline Text:</h4>
                  <p>Use: To underline text.</p>
                  <p>
                    Example:{" "}
                    <code>&lt;u&gt;This text is underlined&lt;/u&gt;</code>.
                  </p>
                </li>
                <li>
                  <h4>Subscript and Superscript:</h4>
                  <p>Use: To create subscript and superscript text.</p>
                  <p>
                    Example: <code>&lt;sub&gt;Subscript&lt;/sub&gt;</code> and{" "}
                    <code>&lt;sup&gt;Superscript&lt;/sup&gt;</code>.
                  </p>
                </li>
                <li>
                  <h4>Strikethrough Text:</h4>
                  <p>Use: To draw a line through text.</p>
                  <p>
                    Example:{" "}
                    <code>
                      &lt;s&gt;This text has a strikethrough&lt;/s&gt;
                    </code>{" "}
                    or{" "}
                    <code>
                      &lt;strike&gt;Another strikethrough example&lt;/strike&gt;
                    </code>
                    .
                  </p>
                </li>
              </ol>
            </p>
            <iframe
              height="500px"
              width="100%"
              title="Formatting Tags"
              src="https://codepen.io/developersahad/embed/YzgjarK?default-tab=html%2Cresult&theme-id=dark"
              frameborder="no"
              loading="lazy"
              allowtransparency="true"
              allowfullscreen="true"
            >
              See the Pen{" "}
              <a href="https://codepen.io/developersahad/pen/YzgjarK">
                Formatting Tags
              </a>{" "}
              by Developer Sahad Sarang (
              <a href="https://codepen.io/developersahad">@developersahad</a>)
              on <a href="https://codepen.io">CodePen</a>.
            </iframe> 

            <h2 className="pt-5">What are 5 New Tags for Formatting Text?</h2>
            <p>
            In recent HTML versions, several new formatting tags have been introduced to enhance the styling options for developers. These include:
            </p>
           
            <p>
            <ol>
        <li>&lt;mark&gt;: Highlights text.</li>
        <li>&lt;small&gt;: Reduces text size.</li>
        <li>&lt;strong&gt; and &lt;b&gt;: Both used for bold text, but &lt;strong&gt; carries more semantic weight.</li>
        <li>&lt;em&gt; and &lt;i&gt;: Both used for italic text, but &lt;em&gt; carries more semantic weight.</li>
        <li>&lt;abbr&gt;: Represents an abbreviation or acronym, providing additional information.</li>
    </ol>
            </p>
            <p>Check below preview:</p>
            <iframe height="300" width="100%" scrolling="no" title="Untitled" src="https://codepen.io/developersahad/embed/jOJpxqp?default-tab=html%2Cresult&theme-id=dark" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
  See the Pen <a href="https://codepen.io/developersahad/pen/jOJpxqp">
  Untitled</a> by Developer Sahad Sarang (<a href="https://codepen.io/developersahad">@developersahad</a>)
  on <a href="https://codepen.io">CodePen</a>.
</iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_formatting;
