import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavItem } from "react-bootstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { HashLink } from "react-router-hash-link";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export default class Navbars extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false,
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  setNavClose = () => {
    this.setState({ navExpanded: false });
  };

  render() {
    return (
      <div>
        <Navbar
          className="navbar-dark fixed-top"
          expand="lg"
          onToggle={this.setNavExpanded}
          expanded={this.state.navExpanded}
        >
          <Navbar.Brand as={Link} to={`${process.env.PUBLIC_URL}/`}>
            <LazyLoadImage
              effect="blur"
              src={`${process.env.PUBLIC_URL}/images/sahad-sarang-logo.png`}
              alt=""
              width="80"
              height="100%"
              className="img-responsive mblogos"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav className="mr-auto">
              <NavItem> </NavItem>
            </Nav>

            <Nav onClick={this.setNavClose}>
              {/* <NavLink  as={Link}  to="/Aboutme">About</NavLink>  */}
              {/* <AnchorLink  href="#services" className="nav-link">SERVICES</AnchorLink> */}
              {/* <Link
                to={`${process.env.PUBLIC_URL}/`}
                className="nav-link"
              >
                HOME
              </Link> */}
              <Link
                to={`${process.env.PUBLIC_URL}/services.html`}
                className="nav-link"
              >
                SERVICES
              </Link>
              {/* <HashLink to="/#footer" className="nav-link">HOME</HashLink>  */}
              <HashLink to="/#aboutme" className="nav-link">
              ABOUT ME
              </HashLink>
              
              <HashLink to="/#clientele" className="nav-link">
                CLIENTELE
              </HashLink>
              <Link
                to={`${process.env.PUBLIC_URL}/free-tutorials.html`}
                className="nav-link"
              >
                FREE TUTORIALS
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/free-ai-tools.html`}
                className="nav-link"
              >
                FREE AI TOOLS
              </Link>
              <a
                href="https://sahadsarang.com/blog/"
                className="nav-link"
              >
                BLOG
              </a>
              <HashLink to="/#contactus" className="nav-link">
                HIRE ME
              </HashLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
