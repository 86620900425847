import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LatestPosts from "./latest-post";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Services() {
  return (
    <div>
      <Helmet>
        <title>Boost Your Online Presence with Website and SEO Services</title>
        <meta
          name="title"
          content="Boost Your Online Presence with Website and SEO Services"
        />
        <meta
          name="description"
          content="Enhance your business online with Sahad Sarang's website development and SEO services. We create impressive websites and improve your visibility to help you succeed online. Explore our customized solutions for your digital growth."
        />
      </Helmet>
      <Container fluid className="text-center">
        <h4 className="pt-5">Services Coming Soon</h4>
      </Container>
    </div>
  );
}
export default Services;
