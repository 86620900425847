import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_Editors() {
  return (
    <div>
      <Helmet>
        <title>HTML Editors | Sahad Sarang</title>
        <meta
          name="description"
          content="Html Editors for Website Development are Visual Studio Code,Sublime Text,Notepad++"
        />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Editors</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/elements.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row> 
            <br/>
            <p>
              HTML editors are tools that help people build websites. They make
              it easy to write and fix the code that creates web pages. These
              editors have features like highlighting code, finishing sentences
              automatically, and showing a preview of the webpage. Popular ones
              include <strong>Sublime Text, Notepad++ and Visual Studio Code</strong>. They're good
              for both beginners and experts, making it simpler to create
              websites that look good and work well.
            </p>
            <h2 className="">How to Create a HTML file</h2>
            <ul>
                <li>1. File make a folder and set your project name</li>
                <li>2. File -&gt; New -&gt; Write the HTML Code</li>
                <li>3. Click on File -&gt; Save as .html</li>
            </ul>
            <p> Here are all three editors list:</p>
             
            <h2 className="">Visual Studio Code</h2>
            <p>
           <a className="tcwhitelink" href="https://code.visualstudio.com" rel="noreferrer noopener nofollow" target="blank">Visual Studio Code</a>, crafted by Microsoft, is a super-friendly code editor for creating websites and applications. You can work in different programming languages, and it even has a built-in terminal for convenience.  
            </p>
            <img src={`${process.env.PUBLIC_URL}/images/html/visual-studio-code.png`} width="100%" />
            
            
            <h2 className="pt-5">Sublime Text</h2>
            <p>
            <a className="tcwhitelink" href="https://www.sublimetext.com/" rel="noreferrer noopener nofollow" target="blank">Sublime Text</a> is a code editor that is simple and easy to use. It is loved by developers for building websites and applications.
            </p>
            <img src={`${process.env.PUBLIC_URL}/images/html/sublime-text.png`} width="100%" />
            
              
            <h2 className="pt-5">Notepad++</h2>
            <p>
            <a className="tcwhitelink" href="https://notepad-plus-plus.org/" rel="noreferrer noopener nofollow" target="blank">Notepad++</a> is a friendly code editor that's easy to use for making websites and apps. It's liked by both new and experienced coders because of its simple design and useful features.
            </p>
            <img src={`${process.env.PUBLIC_URL}/images/html/notepad++.png`} width="100%" />
           
          
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_Editors;
