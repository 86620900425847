import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_Heading() {
  return (
    <div>
      <Helmet>
        <title>HTML Heading | Sahad Sarang</title>
        <meta
          name="description"
          content="HTML headings are like the titles and subtitles of a webpage. They help organize information and make it easier for people to read and navigate your site. "
        />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Heading</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="/html/paragraph.html" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
            <h2 className="pt-5">What's an HTML Heading?</h2>
            <p>
              In simple terms, an HTML heading is a tag that tells your browser,
              "Hey, this is a heading!" It's like giving a title to a section or
              a piece of content on your webpage. Using different heading tags
              helps create a clear structure for your page.
            </p>

            <h2 className="pt-3">How Many Heading Tags Does HTML Have?</h2>
            <p>
              HTML gives you six heading tags to play with, labeled &lt;h1&gt;
              through &lt;h6&gt;. Creating a well-organized structure for your
              content is easy using tags, which represent different levels of
              importance.
            </p>
            <h3>Exploring HTML Heading Tags </h3>
            <ul>
              <li>
                <h4>Heading 1 &lt;h1&gt;</h4>
                <p>
                  This is like the big boss heading. It's usually used for the
                  main title of your entire webpage. There should only be one
                  &lt;h1&gt; per page, and it grabs people's attention right
                  away.
                </p>
              </li>
              <li>
                <h4>Heading 2 &lt;h2&gt;</h4>
                <p>
                  Think of &lt;h2&gt; as the vice president of headings. It's a
                  bit less important than the main title but still carries
                  weight. It's often used to break down the main sections of
                  your page.
                </p>
              </li>
              <li>
                <h4>Heading 3 &lt;h3&gt;</h4>
                <p>
                  Now we're getting into department manager territory.
                  &lt;h3&gt; is great for subsections within the main sections.
                  It helps organize your content further. away.
                </p>
              </li>
              <li>
                <h4>Heading 4 &lt;h4&gt;</h4>
                <p>
                  This is like a team leader within a department. &lt;h4&gt; is
                  used for headings within &lt;h3&gt; sections, providing a more
                  detailed breakdown.
                </p>
              </li>
              <li>
                <h4>Heading 5 &lt;h5&gt;</h4>
                <p>
                  Here, we're at the level of individual contributors.
                  &lt;h5&gt; is perfect for subheadings within &lt;h4&gt;
                  sections, adding more detail to specific areas.
                </p>
              </li>
              <li>
                <h4>Heading 6 &lt;h6&gt;</h4>
                <p>
                  This is the lowest level of heading, like specific tasks
                  within a project. &lt;h6&gt; is great for minor headings or
                  details within specific content areas.
                </p>
              </li>
            </ul>
            <iframe height="500px" width="100%" title="HTML Heading" src="https://codepen.io/developersahad/embed/bGZoEXK?default-tab=html%2Cresult&theme-id=dark" frameborder="no" loading="lazy" allowtransparency="true" allowfullscreen="true">
  See the Pen <a href="https://codepen.io/developersahad/pen/bGZoEXK">
  HTML Heading</a> by Developer Sahad Sarang (<a href="https://codepen.io/developersahad">@developersahad</a>)
  on <a href="https://codepen.io">CodePen</a>.
</iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_Heading;
