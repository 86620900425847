import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Html_Sidebar from "./sidenav";
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Col,
  Row,
  Tab,
  Carousel,
  Modal,
} from "react-bootstrap";
function Html_colors() {
  return (
    <div>
      <Helmet>
        <title>HTML Colors | Sahad Sarang</title>
        <meta
          name="description"
          content="By using Html colors strategically, you can create visually appealing and user-friendly interfaces."
        />
      </Helmet>
      <Container fluid className="tbox tutorial code navbelowmg">
        <Row>
          <Col lg="2" md="5" className="col-12 mbpdt0 pdl0">
            <Html_Sidebar />
          </Col>

          <Col lg="8" md="5" className="offset-lg-1 pt-5 col-12 ">
            <h1>HTML Colors</h1>
            <Row className="pt-5">
              <Col lg="6" md="6" className="text-left col-6">
                <Link to="/html/introduction.html" className="orgbtn btn">
                  Home
                </Link>
              </Col>
              <Col lg="6" md="6" className="text-right col-6">
                <Link to="#" className="orgbtn btn">
                  Next
                </Link>
              </Col>
            </Row>
            <h2 className="pt-5">What are HTML Colors?</h2>
            <p>
              HTML colors are an essential aspect of web design, adding vibrancy
              and personality to your websites. By using colors strategically,
              you can create visually appealing and user-friendly interfaces.
            </p>

            <h3 className="pt-3">Changing Colors in HTML</h3>
            <p>
              <ol>
                <li>
                  <h4>Text Color</h4>
                  <p>
                    Let's begin with the most basic aspect: changing the text
                    color. To do this, you can use the color property within an
                    inline style.
                    <br /> Here's an example:
                  </p>
                  <Container className="code-container">
                    <pre class="language-markup snipcss-CiZSg" tabindex="0">
                      <code class="language-markup">
                        {` <p style="color: red;">This text is in red color!</p>`}
                      </code>
                    </pre>
                  </Container>
                  <iframe
                    height="300px"
                    width="100%"
                    title="HTML Colors"
                    src="https://codepen.io/developersahad/embed/WNmgGmY?default-tab=html%2Cresult&theme-id=dark"
                    frameborder="no"
                    loading="lazy"
                    allowtransparency="true"
                    allowfullscreen="true"
                  >
                    See the Pen{" "}
                    <a href="https://codepen.io/developersahad/pen/WNmgGmY">
                      HTML Colors
                    </a>{" "}
                    by Developer Sahad Sarang (
                    <a href="https://codepen.io/developersahad">
                      @developersahad
                    </a>
                    ) on <a href="https://codepen.io">CodePen</a>.
                  </iframe>
                  <p>
                    Replace with any color you desire. You can use color names
                    like "blue" or "green," or employ hexadecimal codes such as
                    "#FF5733."
                  </p>
                </li>
                <li>
                  <h4>Link Colors in HTML</h4>
                  <p>
                    Changing link colors is crucial for maintaining a consistent
                    and visually appealing design. You can achieve this by using
                    the color property for regular links and text decoration
                  </p>
                  <Container className="code-container">
                    <pre class="language-markup snipcss-CiZSg" tabindex="0">
                      <code class="language-markup">
                        {` <a href="#" style="color: #3498db;">This is a blue link</a>
                    <a href="#" style="color: #e74c3c; text-decoration: none;">This link turns red on hover</a>`}
                      </code>
                    </pre>
                  </Container>
                  <p>Feel free to experiment with different colors and styles to match your website's theme.</p>
                </li>
                <li>
                  <h4>Background Color:</h4>
                  <p>To change the background color of an HTML element, use the background-color property. Here's an example for a paragraph with a yellow background:</p>
                  <Container className="code-container">
                    <pre class="language-markup snipcss-CiZSg" tabindex="0">
                      <code class="language-markup">
                        {`<p style="background-color: yellow;">This paragraph has a yellow background</p>`}
                      </code>
                    </pre>
                  </Container>
                </li>
                <li>
                  <h4>Border Color:</h4>
                  <p>Borders can enhance the structure of your webpage. To change the border color, use the border property. Here's a simple example:</p>
                  <Container className="code-container">
                    <pre class="language-markup snipcss-CiZSg" tabindex="0">
                      <code class="language-markup">
                        {`<div style="border: 2px solid #27ae60; padding: 10px;">This div has a green border</div>`}
                      </code>
                    </pre>
                  </Container>
                  <p>Adjust the border width, style, and color to achieve the desired look.</p>
                </li>
                <li>
                  <h4>Text Color (Alternative)</h4>
                  <p>Use: To draw a line through text.</p>
                  <p>
                  <Container className="code-container">
                    <pre class="language-markup snipcss-CiZSg" tabindex="0">
                      <code class="language-markup">
                        {`.text-green{color: #2ecc71;}
                        <p class="text-green">This text is styled using a CSS class</p>
                        `}
                      </code>
                    </pre>
                  </Container>
                  </p>
                  <p>This approach promotes code reusability and maintains a clean structure.</p>
                </li>
              </ol>
            </p>
              
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Html_colors;
