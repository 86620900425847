import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  sidebarClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

function Html_Sidebar() {
  const [collapsed, setCollapsed] = React.useState(false);

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  React.useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is less than or equal to 767px
      if (window.innerWidth <= 767) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="ssecph">
      <main>
        <div>
          <button className="sb-button sbbtn" onClick={handleToggleSidebar}>
            ☰
          </button>
        </div>
      </main>
      <Sidebar
        collapsed={collapsed}
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "#1e304d",
            borderColor: "transparent",
          },
        }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0)
                return {
                  color: disabled ? "#f5d9ff" : "#fff", 
                  backgroundColor: active ? "#eecef9" : undefined,
                }; 
            },
          }}
        id="sidenav">
          <h5 className="pt5">HTML 5 Tutorial</h5>
          
          <div className="boxpadding"></div>
          {/* Menu items go here */}
          <MenuItem component={<Link to="/html/introduction.html" />} >
            HTML Introduction
          </MenuItem>
          <MenuItem component={<Link to="/html/editors.html" />}>HTML Editors</MenuItem>
          <MenuItem component={<Link to="/html/elements.html" />}>HTML Elements</MenuItem>
          <MenuItem component={<Link to="/html/layouts.html" />}>HTML Layouts</MenuItem>
          <MenuItem component={<Link to="/html/heading.html" />}>HTML Headings</MenuItem>
          <MenuItem component={<Link to="/html/paragraph.html" />}>HTML Paragraphs</MenuItem>
          <MenuItem component={<Link to="/html/inline-style.html" />}>HTML Inline Style</MenuItem>
          <MenuItem component={<Link to="/html/formatting-tags.html" />}>HTML Formatting tags</MenuItem>
          <MenuItem component={<Link to="/html/comments.html" />}>HTML Comments</MenuItem>
          <MenuItem component={<Link to="/html/colors.html" />}>HTML Colors</MenuItem>
          <MenuItem>HTML CSS</MenuItem>
          <MenuItem>HTML Images</MenuItem>
          <MenuItem>HTML Links</MenuItem>
          <MenuItem>HTML Title Tag</MenuItem>
          <MenuItem>HTML Fav icon</MenuItem>
          <MenuItem>HTML ID</MenuItem>
          <MenuItem>HTML Class</MenuItem>
          <MenuItem>HTML Lists UL OL</MenuItem>
          <MenuItem>HTML Iframes</MenuItem>
          <MenuItem>HTML Forms</MenuItem>
          <MenuItem>HTML Form Elements</MenuItem>
          <MenuItem>HTML Form Attributes</MenuItem>
          <MenuItem>HTML Form Input Type</MenuItem>
          <MenuItem>HTML Media</MenuItem>
          <MenuItem>Html video</MenuItem>
          <MenuItem>HTML audio</MenuItem>
          {/* Add other menu items */}
        </Menu>
      </Sidebar>
    </section>
  );
}

export default Html_Sidebar;
