import React, { useEffect, useState,Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LatestPosts from "./latest-post"; 
import axios from 'axios';
import _ from 'lodash'
import {
  Nav,
  Container,
  Accordion,
  Card,
  Button,
  Form,
  Col,
  Row,
  Tab,
  Carousel,
  Modal, FormControl
} from "react-bootstrap";

const config = {
  cors: 'https://cors-anywhere.herokuapp.com/', // <optional> doesn't display the cors error
  formUrl: 'https://docs.google.com/forms/u/2/d/e/1FAIpQLSe31kBKT4ICa9w3jpsCBVXYVjA37ZEqYVXUYVuxAvSXTXNyrA/formResponse'
};

const Input = ({ name, doChange, placeholder, type }) => {
  return (
      <label htmlFor={name} >
          <Form.Control type={type} id={name} name={name} onChange={doChange} placeholder={placeholder} required />

      </label>
  )
}
class Home extends React.Component {
  state = {
      inputs: {
          name: { id: 2138541769, value: '' },
          email: { id: 1593257579, value: '' },
          contact: { id: 1550330982, value: '' }, 
          message: { id: 2032943411, value: '' }
      },
  }

  doSubmit = async (e) => {
      e.preventDefault();

      const { inputs } = this.state;
      const formData = new FormData();

      _.map(inputs, (item) => {
          formData.append(`entry.${item.id}`, item.value)
      });

      await axios({
          url: `${config.formUrl}`,
          method: 'post',
          data: formData,
          responseType: 'json'
      })
          .then(function (response) {
              console.log('response', response);

          })
          .catch(function (error) {
              //console.log('err', error);
             window.location.replace("/thank-you.html") 
          })
          
          
  }

  handleChange = (e) => {
      const { value, name } = e.target;
      const { inputs } = this.state;

      inputs[name].value = value;

      this.setState({
          inputs
      });
  }
  render() { 
  return (
    <div>
      <Helmet>  
<title>Sahad Sarang | Website Developer | SEO Expert</title>
<link rel="canonical" href="https://sahadsarang.com" />
<meta name="title" content="Sahad Sarang | Website Developer | SEO Expert" />
<meta name="description" content="I possess six years of experience in website development and SEO, with expertise spanning various niches, including wellness resorts, diabetic chocolate, portfolio websites, e-commerce websites, and shopping malls." />
 
<meta property="og:type" content="website" />
<meta property="og:url" content="https://sahadsarang.com/" />
<meta property="og:title" content="Sahad Sarang | Website Developer | SEO Expert" />
<meta property="og:description" content="I possess six years of experience in website development and SEO, with expertise spanning various niches, including wellness resorts, diabetic chocolate, portfolio websites, e-commerce websites, and shopping malls." />
<meta property="og:image" content="https://sahadsarang.com/images/sarang.png" />
 
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://sahadsarang.com/" />
<meta property="twitter:title" content="Sahad Sarang | Website Developer | SEO Expert" />
<meta property="twitter:description" content="I possess six years of experience in website development and SEO, with expertise spanning various niches, including wellness resorts, diabetic chocolate, portfolio websites, e-commerce websites, and shopping malls." />
<meta property="twitter:image" content="https://sahadsarang.com/images/sarang.png" /> 


 </Helmet>
      <Container fluid className="navbelowmg">
     

        <Row>
          <Col lg="5" md="5" className="pt-5 mbpdt0 offset-lg-1 col-12">
            <h1 className="pt-5 mbpdt0">
              Sahad <br /> Sarang
            </h1>
            <h2 className="technicalseo margb3 mbmbd0">Technical SEO Expert</h2>

            <div className="text-center d-none d-sm-block">
              <span className="pt-5  ">
                <a href="https://github.com/sahadsarang" target="blank">
                  <img
                    className="cstmmargin"
                    src={`${process.env.PUBLIC_URL}/images/github.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/sahad-sarang/"
                  target="blank"
                >
                  <img
                    className="cstmmargin"
                    src={`${process.env.PUBLIC_URL}/images/linkedin.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/sahad-sarang/"
                  target="blank"
                >
                  <img
                    className="cstmmargin"
                    src={`${process.env.PUBLIC_URL}/images/twitter.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEHWtI5QaSyux4zM6q9ZcRQ"
                  target="blank"
                >
                  <img
                    className="cstmmargin"
                    src={`${process.env.PUBLIC_URL}/images/youtube.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.instagram.com/developersahad/"
                  target="blank"
                >
                  <img
                    className="cstmmargin"
                    src={`${process.env.PUBLIC_URL}/images/instagram.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.facebook.com/developersahad"
                  target="blank"
                >
                  <img
                    className="cstmmargin"
                    src={`${process.env.PUBLIC_URL}/images/facebook.png`}
                    width="36"
                  />
                </a>
              </span>
            </div>
            
           
          </Col>
          <Col lg="5" md="6" className="col-12 pt-5 mbpdt0  mgtp10">
            {/* mobile */}
            <img className="d-none d-sm-block"
              src={`${process.env.PUBLIC_URL}/images/sarang.png`}
              width="100%"
            />
            <div className="d-block d-sm-none">
            <Row>
            <Col className="col-1 mbbpdlr0">
            <div className="text-center ">
              <span className="pt-5  ">
                <a href="https://github.com/sahadsarang" target="blank">
                  <img
                    className="cstmmargin castimg"
                    src={`${process.env.PUBLIC_URL}/images/github.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/sahad-sarang/"
                  target="blank"
                >
                  <img
                    className="cstmmargin castimg"
                    src={`${process.env.PUBLIC_URL}/images/linkedin.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/sahad-sarang/"
                  target="blank"
                >
                  <img
                    className="cstmmargin castimg"
                    src={`${process.env.PUBLIC_URL}/images/twitter.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCEHWtI5QaSyux4zM6q9ZcRQ"
                  target="blank"
                >
                  <img
                    className="cstmmargin castimg"
                    src={`${process.env.PUBLIC_URL}/images/youtube.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.instagram.com/developersahad/"
                  target="blank"
                >
                  <img
                    className="cstmmargin castimg"
                    src={`${process.env.PUBLIC_URL}/images/instagram.png`}
                    width="36"
                  />
                </a>
                <a
                  href="https://www.facebook.com/developersahad"
                  target="blank"
                >
                  <img
                    className="cstmmargin castimg"
                    src={`${process.env.PUBLIC_URL}/images/facebook.png`}
                    width="36"
                  />
                </a>
              </span>
            </div>
            </Col>
            <Col className="col-11">
            <img
              src={`${process.env.PUBLIC_URL}/images/sarang.png`}
              width="100%"
            />
            </Col>
            </Row>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Second fold */}
      <Container fluid id="aboutme" className="aboutme bgsecond pt-5 pb-5">
        <Row>
          <Col lg="6" sm="12" className="col-3 pt-5 pb-5">
            <Row>
              <Col lg="3" sm="3">
                <h1 className="custf">About Me</h1>
              </Col>
              <Col lg="9" sm="8" className="d-none d-sm-block">
                <img
                  src={`${process.env.PUBLIC_URL}/images/close-photo.png`}
                  width="100%"
                />
              </Col>
            </Row>
          </Col>
          <Col lg="5" sm="12" className="col-9 pt-5 mbbpdl0">
            
            <Row><Col lg="12" className="col-6 mbbpdlr0">
              <p>
                Experienced Website Developer and SEO Expert with 6+ years in
                the industry. Specializing in crafting effective online
                solutions for diverse brands, from wellness resorts to niche
                markets like Diabetic Chocolates. Let's elevate your online
                presence together.
              </p>
            </Col>
            <Col className="col-6 d-block mbbpdlr0 d-sm-none">
              <img
                src={`${process.env.PUBLIC_URL}/images/close-photo.png`}
                width="100%"
              />
            </Col>
            </Row>
            <br />
            <Row><Col lg="12" className="col-12 mbbpdl0">
            <h5>Proficient in:</h5>
            <Row className="mt-4 ">
              <Col lg="2" className="col-3">
                <p>HTML5</p>
              </Col>
              <Col lg="10" className="col-9">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    100%
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="2" className="col-3">
                <p>CSS3</p>
              </Col>
              <Col lg="10" className="col-9">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    100%
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="2" className="col-3">
                <p>JQuery</p>
              </Col>
              <Col lg="10" className="col-9">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "100%" }}
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    100%
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="2" className="col-3">
                <p>WordPress</p>
              </Col>
              <Col lg="10" className="col-9">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "97%" }}
                    aria-valuenow="97"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    97%
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="2" className="col-3">
                <p>Shopify</p>
              </Col>
              <Col lg="10" className="col-9">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "92%" }}
                    aria-valuenow="92"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    92%
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="2" className="col-3">
                <p>SEO</p>
              </Col>
              <Col lg="10" className="col-9">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "96%" }}
                    aria-valuenow="96"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    96%
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="2" className="col-3">
                <p>Wix</p>
              </Col>
              <Col lg="10" className="col-9">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "95%" }}
                    aria-valuenow="95"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    95%
                  </div>
                </div>
              </Col>
            </Row>
            </Col></Row>
          </Col>
        </Row>
      </Container>
      {/* Third Fold */}
      <Container id="clientele" fluid className="padtb15 mbbpadtb3">
        <Row> 
          <Col lg="5" sm="12" className="offset-lg-1 col-12 mbtext-center">
            <Row>
            
              <Col lg="4" md="4" sm="3" className="col-6  margtbaauto pt-5 offset-lg-0">
                <img
                  src={`${process.env.PUBLIC_URL}/images/clients/oberoi-mall.png`}
                  width="100%"
                  className="mx-auto"
                />
              </Col>
              <Col lg="4" md="4" sm="3" className="col-6  margtbaauto pt-5 ">
                <img
                  src={`${process.env.PUBLIC_URL}/images/clients/adiabetic-chef.png`}
                  width="100%"
                  className="mx-auto"
                />
              </Col>
              <Col lg="4" md="4" sm="3" className="col-6 margtbaauto pt-5">
                <img
                  src={`${process.env.PUBLIC_URL}/images/clients/regalia.png`}
                  width="100%"
                  className="mx-auto"
                />
              </Col>
            {/* </Row>

            <Row className="pt-5"> */}
              
              <Col lg="4" md="4" sm="3" className="col-6 margtbaauto pt-5">
                <img
                  src={`${process.env.PUBLIC_URL}/images/clients/yashgreen.png`}
                  width="100%"
                  className="mx-auto"
                />
              </Col>
              <Col lg="4" md="4" sm="3" className="col-6 margtbaauto pt-5">
                <img
                  src={`${process.env.PUBLIC_URL}/images/clients/machaand.png`}
                  width="100%"
                  className="mx-auto"
                />
              </Col>
              <Col lg="3" md="4" sm="3" className="col-6 margtbaauto pt-5">
                <img
                  src={`${process.env.PUBLIC_URL}/images/clients/virart.png`}
                  width="100%"
                  className="mx-auto"
                />
              </Col>
            {/* </Row>
            <Row className="pt-5"> */}
              
              <Col lg="2" md="4" sm="3" className="col-6 margtbaauto pt-5 offset-lg-1">
                <img
                  src={`${process.env.PUBLIC_URL}/images/clients/marcelo.png`}
                  width="60"
                  className="mx-auto"
                />
              </Col>
            </Row>
          </Col> 
          <Col lg="6" sm="12" className="col-12 order-first order-sm-last mbtext-center">
            <h2 className="mbtext-center">
              BRANDS
              <br /> I'VE
              <br /> HELPED
            </h2>
          </Col>
        </Row>
      </Container>
      {/* Free Tutorials */}
      <Container id="freetutorials" fluid className="padtb15 bgfreetutorials pt-5">
        <h2 className="text-left pt-8">FREE TUTORIALS</h2>
        <Row className="">
          <Col lg="4" md="4" className="tutorialbox mbbpadtb3">
            <img src="images/courses-orange-blue.png" width="100%" />
            <div className="tutorial-left">
              <h3>
                HTML5 Full
                <br /> Course
              </h3>
              <a href="" className="btn btn-white">
                Learn Now
              </a>
            </div>
            <div className="tutorial-right">
              <img src="images/html.png" width="50%" />
            </div>
          </Col>
          <Col lg="4" md="4" className="tutorialbox mbbpadtb3">
            <img src="images/courses-blue.png" width="100%" />
            <div className="tutorial-left">
              <h3>
                CSS3 Full
                <br /> Course
              </h3>
              <a href="" className="btn btn-white">
                Learn Now
              </a>
            </div>
            <div className="tutorial-right">
              <img src="images/css-3.png" width="50%" />
            </div>
          </Col>
          <Col lg="4" md="4" className="tutorialbox mbbpadtb3">
            <img src="images/courses-yellow-blue.png" width="100%" />
            <div className="tutorial-left">
              <h3>
                Javascript Full
                <br /> Course
              </h3>
              <a href="" className="btn btn-white">
                Learn Now
              </a>
            </div>
            <div className="tutorial-right">
              <img src="images/java-script-logo.png" width="50%" />
            </div>
          </Col>
        </Row>
      </Container>
      {/* Latest Post */}
      <Container fluid className="mbbpadtb3 padtb5">
      <LatestPosts/>
      </Container>
      {/* Contact us */}
      <Container fluid className="mbbpadtb3 padtb5">
        <h2 id="contactus" className="text-center  ">
          GET IN TOUCH
        </h2>
        <Container>
          <Row className="boxoveform">
            <Col lg="7" md="6" className="contbox tutorialbox bgconctform">
              <p>Leave a message and I’ll get back to you shortly</p>
              {/* <form className="form-horizontal" >
                <div className="form-group">
                  <input
                    id="Name"
                    name="Name"
                    type="text"
                    placeholder="Name"
                    className="form-control input-md"
                    required=""
                  />
                </div>
                <div className="form-group">
                  <input
                    id="Email"
                    name="Email"
                    type="text"
                    placeholder="Email"
                    className="form-control input-md"
                    required=""
                  />
                </div>
                <div className="form-group">
                  <input
                    id="contact"
                    name="contact"
                    type="text"
                    placeholder="Contact Number"
                    className="form-control input-md"
                    required=""
                  />
                </div>

                <div className="form-group">
                  <input
                    id="Location"
                    name="Location"
                    type="text"
                    placeholder="Location"
                    required=""
                    className="form-control input-md"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    rows="6"
                    className="form-control"
                    id="help"
                    name="help"
                    required=""
                  >
                    How can I help you
                  </textarea>
                </div>
                <div className="form-group">
            <a href="" className="btn-orange">Submit</a>
            </div>
              </form> */}
              <form onSubmit={this.doSubmit} className="form-horizontal">
              <div className="form-group">
      
        {/* <input className="form-control input-md" name="name" doChange={this.handleChange} placeholder="Name*" required /> */}
        <Input name="name" width="100%" doChange={this.handleChange} placeholder="Name*" className="form-control" required />

      </div> 
      <div className="form-group">
      <input className="form-control" type="email" label="email" name="email" onChange={this.handleChange} placeholder="E-mail*" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required />
        </div> 
      <div className="form-group">
        
        <input type="tel" className="form-control input-md" name="contact" onChange={this.handleChange} placeholder="Phone Number*" required maxlength="10" pattern="[0-9]{10}"/>
        </div> 
      
      <div className="form-group">
        
        <textarea className="form-control" placeholder="Message" name="message" onChange={this.handleChange} as="textbox"  rows={3}  />
        </div> 
      <div className="form-group">
      <button type="submit"  className="btn-orange"  value="Submit">Submit</button>
      </div>
    </form>
            </Col>
            <Col lg="5" md="6" className="tutorialbox pdlr0 order-sm-last order-first">
              <img
                src="images/contact-us.jpg"
                className="conrghbox img-fluid"
              />
            </Col>
           
          </Row>
        </Container>
      </Container> 
    
     
    </div>
  )
}
}
export default Home;
